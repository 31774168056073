import React from 'react';

const Footer = () => {
    return (
      <footer className="bg-gray-900 text-white py-8">
        <div className="container mx-auto text-center">
        <p>Made with 💖 by your dude❤️</p>
        </div>
      </footer>
    );
  };
  
export default Footer;
